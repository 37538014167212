.btn{
  border-radius: 8px;
  font-size: 13px;
  font-weight: 700;
  padding: 8px 20px;
  &.btn-primary{
   @include btn($primary-color, $white);
   &:hover {
    background-color: $white !important;
    color: $primary-color !important;
   }
  }
  &.btn-gray{
   @include btn(#f0f0f0, $black)
  }
  &.btn-secondary{
   @include btn($secondary-color, $white)
  }
  &.btn-outline-primary{
    @include btn-outline($primary-color)
  }
  &.btn-outline-dark{
    @include btn-outline($black);
    &.primary{
      &.active, &:hover{
        background-color: $primary-color !important;
        border-color: $primary-color !important;
        color: #ffffff;
      }
    }
  }
  &.btn-min{
    min-width: 138px;
  } &.btn-170{
    min-width: 170px;
  }
  &.btn-underline {
    padding: 0;
    text-decoration: underline;
    color: $primary-color;
    background-color: transparent;
  }
  &:focus {
    box-shadow: none;
  }
}
.icon-btn{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  border: none;
  background-color: transparent;
}

.dark{
  .btn{
    &.btn-outline-dark{
      @include btn-outline($white);
    }
    &.btn-outline-white {
      color: $white;
    }
    &.btn-outline-primary{
      border-color: $white !important;
      background-color: $white !important;
      &:hover {
        border-color: $primary-color !important;
        background-color: $primary-color !important;
      }
    }
  }
}
.btn-default {
  padding: 0;
  border: none;
  background-color: transparent;
}

.btn-icon-box{
  width: 30px;
  height: 30px;
  border-radius: 8px;
  color: #ffffff;
  border: solid 1px $primary-color;
  background-color: $primary-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.dark{
  .btn-icon-box{
    border: solid 1px rgba(255, 255, 255, 0.3);
    background-color: #000;
  }
}
