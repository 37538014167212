@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

$black: #040d27;
$white: #ffffff;
$primary-green: #419d78;
$primary-color: #db683d;
$secondary-color: #273879;
$golden: #c69833;
$sky: #73b5da;
$green: #5a9b7b;
$yellow:  #ffc107;
$orange: #eb602d;
$body: #f9f9f9;
$gray: #ccc;
$side-bar-width : 230px;
$dim-white: #dbdbdf;
$border-color: #dddddd;

$dark-primary-bg: #181f36;
$dark-secondary-bg: #020d29;

$dusk-blue: #23387d;
$dark-border-color : $dusk-blue;

$primary-font-family: 'Cairo', sans-serif;
$secondary-font-family: 'Open Sans', sans-serif;
