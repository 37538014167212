/*=============General Styling===============*/
body {
  font-family: $primary-font-family;
  color: $black;
  overflow-x: hidden;
  height: 100%;
  background-color: $body;
}

.dark{
  color: #ffffff;
}
a,
button {
  color: inherit;
  text-decoration: none;
  &:focus,
  &:active {
    color: inherit;
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &.underline{
    &, &:hover{
      text-decoration: underline !important;
    }
  }
}

a {
  // transition: all 0.2s ease-in-out;
  &:hover {
    color: $primary-color;
    text-decoration: none !important;
  }
}

*,
.fancy-scroll {
  &::-webkit-scrollbar-track {
    background-color: $gray;
    border-radius: 10px;
    visibility: hidden;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.3s;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #9d9d9d;
    }
  }
  &.scroll-transparent {
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }
}

.fancy-scroll {
  overflow-y: auto;
}
