.box{
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}

.black-link{
  font-size: 12px;
  font-weight: 600;
}

i.middle {
  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.custom-checkbox{
  &.checkbox-20{
    input{
      width: 20px;
      height: 20px;
    }
  }
}


.dark{
  .box{
    background-color: $dark-primary-bg;
  }

  .custom-checkbox{
      input{
      border-color: #ffffff ;
        &:checked{
          border-color: $primary-color;
        }
      }
  }
}

