.secondary-font {
  font-family: $secondary-font-family;
}
.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.text-primary {
  color: $primary-color !important;
}
.text-sky {
  color: $sky !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.primary-link {
  &,
  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
  }
  color: $primary-color !important;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    color: #000000 !important;
  }
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.fw-700 {
  font-weight: bold;
}
.fw-400 {
  font-weight: 400;
}
.pointer-event {
  cursor: pointer;
}
.pointer-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.overflow-text {
  white-space: nowrap !important;
  width: 200px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline-block;
}

.col-s {
  width: 5vw !important;
  min-width: 5vw !important;
  max-width: 5vw !important;
}

.status {
  color: #ffffff !important;
  min-width: 120px;
  height: 28px;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.side-bar{
  .tooltip-arrow.arrow{
    display: none !important;
  }
}
